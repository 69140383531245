import 'firebase/auth';

import firebase from 'firebase/app';
import {
  AuthAction,
  withAuthUser,
  withAuthUserTokenSSR,
} from 'next-firebase-auth';
import React, { FC, useEffect, useState } from 'react';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { PageWrapper } from 'client/components/generic/PageWrapper';
import { MetaData } from 'client/enums/meta';
import { Images } from 'client/enums';

const Auth: FC = () => {
  const [renderAuth, setRenderAuth] = useState(false);
  useEffect(() => {
    if (typeof window !== 'undefined') {
      setRenderAuth(true);
    }
  }, []);

  if (!renderAuth) {
    return null;
  }

  return (
    <PageWrapper
      className="flex justify-center py-12 px-4 sm:px-6 lg:px-8"
      description={MetaData.LOGIN_DESCRIPTION}
      title={MetaData.LOGIN_TITLE}
    >
      <div className="space-y-20 items-center" data-cy="login">
        <div className="flex w-full justify-center items-center">
          <img alt="Logo" className="w-[500px]" src={Images.FCLogo} />
        </div>
        <StyledFirebaseAuth
          firebaseAuth={firebase.auth()}
          uiConfig={{
            signInFlow: 'popup',
            signInOptions: [
              {
                provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
              },
            ],
            credentialHelper: 'none',
            callbacks: {
              signInSuccessWithAuthResult: () => false,
            },
          }}
        />
      </div>
    </PageWrapper>
  );
};

export const getServerSideProps = withAuthUserTokenSSR({
  whenAuthed: AuthAction.REDIRECT_TO_APP,
})();

export default withAuthUser({ whenAuthed: AuthAction.REDIRECT_TO_APP })(Auth);
